<template>
    <div id="page-user-list">

      <div class="flex flex-wrap items-center">


        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
            <div class="vx-col md:w-1/1 w-full">
        <vx-card title="เลือกวันเวลา">
          <br />
          <div>
            <div class="vx-col md:w-1/2 w-full">
              <label>เริ่มต้น: </label>
              <flat-pickr
                v-model="before_datetime"
                placeholder="Date Time"
              />
            </div>
            <br />
            <br />
            <div class="vx-col md:w-1/2 w-full">
              <label> สิ้นสุด: </label>
              <flat-pickr
                v-model="after_datetime"
                placeholder="Date Time"
              />
            </div>
          </div>
          <br />
          <br />
          <vs-button  color="primary" @click="selectdate_time ()">ค้นหา</vs-button>
          <br />
          <br />
          <br />
          <h6 class="text-danger mb-4"> * ยอดอัพเดททุก 4 ชั่วโมง (อัพเดทล่าสุด : {{ lastupdate }})</h6>
        </vx-card>
      </div>
          <br>
          <div v-if="table_data.length!==0">
            <div class="vx-row">
             <div v-for="item in table_data" :key="item.sm_type" class="vx-col w-full sm:w-1/4 md:w-1/4 lg:w-1/4 xl:w-1/4">
              <statistics-card-line v-if="item.sum < 0" hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="currency(item.sum)"
                :statisticTitle="item.sm_type" color="danger" />
                <statistics-card-line v-else hideChart class="mb-base" icon="DollarSignIcon" icon-right :statistic="currency(item.sum)"
                :statisticTitle="item.sm_type" color="success" />
            </div>
          </div>
          </div>
          <div v-else >
            <h1 >ไม่พบมีข้อมูล</h1>
          </div>
        </div>
      </div>

    </div>

  </template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import moment from 'moment'
export default {
  components: {
    flatPickr,
    StatisticsCardLine,
    vSelect
  },
  data () {
    return {
      popupgame:false,
      before_datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      after_datetime: moment
        .tz('Asia/Bangkok')
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      table_data: [],
      lastupdate: moment.tz('Asia/Bangkok').format('YYYY-MM-DD H:mm:ss'),
      index:0
    }
  },
  async mounted () {
    await this.get_data()
  },
  methods: {
    async winlosegame (data) {
      this.popupgame = true
      this.index = data
    },
    async  selectdate_time () {
      if (this.month === moment.tz('Asia/Bangkok').format('MM') && this.year === moment.tz('Asia/Bangkok').format('YYYY')) {
        await this.get_data()
      } else {
        await axios
          .get(`dashboardwinloss/selectmonth/${this.before_datetime}/${this.after_datetime}`, {
          })
          .then(response => (this.table_data = response.data))
        if (this.table_data.length === 0) {
          this.table_data_all = ''
        } else {
          this.table_data_all = this.table_data[0].sm_all
        }
      }

    },
    async get_data () {

      await axios
        .get(`dashboardwinloss/selectmonth/${this.before_datetime}/${this.after_datetime}`, {
        })
        .then(response => (this.table_data = response.data))
    },
    currency (amount) {
      amount = (amount * 1.00).toFixed(2)
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(amount)
        return (amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        // amount = String(Math.abs(amount))
        // return `-${(amount).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
      }

    }
  }
}

</script>
